import { Link } from '@chocolate-soup-inc/cs-frontend-components';
import { TCarriers, TGiftShipmentFieldsFragment, TShipmentStatuses } from '../../generated/graphql';
import styles from './StatusCell.module.scss';
import clsx from 'clsx';
import tableStyles from '../../components/TablePage/TablePage.module.scss';

export const getShipmentFinalStatus = (shipment?: TGiftShipmentFieldsFragment | null) => {
  if (shipment?.label?.carrier === TCarriers.Ups) return 'Unavailable';
  const { status } = shipment || {};

  switch (status) {
    case TShipmentStatuses.Packaging:
      return 'Assembling';
    case TShipmentStatuses.ReadyToShip:
    case TShipmentStatuses.PrintingLabel:
      return 'Awaiting Shipment';
    case TShipmentStatuses.LabelPrinted:
      return 'Shipping Label Printed';
    case TShipmentStatuses.Shipped:
      return 'In Transit';
    case TShipmentStatuses.Delivered:
      return 'Delivered';
    case TShipmentStatuses.ReadyForPickup:
      return 'Available for Pickup';
    case TShipmentStatuses.Returned:
      return 'Returned';
    case TShipmentStatuses.Unavailable:
    default:
      return 'Unavailable';
  }
};

export const getShipmentFinalColor = (shipment?: TGiftShipmentFieldsFragment | null) => {
  if (shipment?.label?.carrier === TCarriers.Ups) return '#92979D';
  const { status } = shipment || {};

  switch (status) {
    case TShipmentStatuses.Packaging:
    case TShipmentStatuses.ReadyToShip:
    case TShipmentStatuses.PrintingLabel:
    case TShipmentStatuses.Unavailable:
    default:
      return '#92979D';
    case TShipmentStatuses.LabelPrinted:
    case TShipmentStatuses.Shipped:
      return '#3E66FB';
    case TShipmentStatuses.Delivered:
      return '#66CA65';
    case TShipmentStatuses.ReadyForPickup:
      return '#F3BB1C';
    case TShipmentStatuses.Returned:
      return '#F03738';
  }
};

export const getTrackingLink = (shipment: TGiftShipmentFieldsFragment) => {
  const { trackingNumber, label } = shipment;
  const { carrier = undefined } = label || {};

  switch (carrier) {
    case 'canada_post':
      return `https://www.canadapost-postescanada.ca/track-reperage/en#/search?searchFor=${trackingNumber}`;
    case 'purolator_ca':
      return `https://www.purolator.com/en/shipping/tracker?pin=${trackingNumber}`;
    case 'dhl_express_canada':
      return `https://www.dhl.com/br-en/home/tracking/tracking-express.html?submit=1&tracking-id=${trackingNumber}`;
    case 'ups':
      return `https://www.ups.com/track?track=yes&trackNums=${trackingNumber}&loc=en_US&requester=ST/trackdetails`;
    default:
      break;
  }
};

export const TrackingLink = (shipment: TGiftShipmentFieldsFragment) => {
  return (
    <div className={clsx(tableStyles.tableSpacePrimary)}>
      {shipment.trackingNumber ? (
        <Link
          href={getTrackingLink(shipment)}
          label={shipment.trackingNumber}
          icon={true}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();

            window.open(getTrackingLink(shipment));
          }}
        />
      ) : (
        '-'
      )}
    </div>
  );
};

export const StatusCell = (shipment?: TGiftShipmentFieldsFragment | null) => {
  const text = getShipmentFinalStatus(shipment);
  const color = getShipmentFinalColor(shipment);

  return (
    <div className={clsx(styles.statusContainer, tableStyles.tableSpacePrimary)}>
      <span className={styles.statusIndicator} style={{ backgroundColor: color }} />
      <span className={styles.statusText}>{text}</span>
    </div>
  );
};
