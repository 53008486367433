import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorPage, LoadingPage, TableInner, readableDate } from '@chocolate-soup-inc/cs-frontend-components';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { DELIVERIES_PATH, DELIVERY_PATH, DETAILS_PATH } from '../../../routes/paths';
import {
  StatusCell as BasicStatusCell,
  TrackingLink as BasicTrackingLink,
  getShipmentFinalStatus,
} from '../../../shared/shipments/utils';
import { usePrivateCompanyContext } from '../../../routes/outlets/PrivateCompanyOutlet';
import { sortShipments, TListShipment, useQueryAllShipments } from '../../../entities/shipment/shared';
import { CellContext } from '@tanstack/react-table';
import { TablePage } from '../../../components/TablePage/TablePage';
import { quickScore } from 'quick-score';
import { Filters, TFiltersProps } from '../../../components/filters/Filters';
import clsx from 'clsx';
import tableStyles from '../../../components/TablePage/TablePage.module.scss';

export const StatusCell = React.memo((props: CellContext<TListShipment, unknown>) => {
  const { cell } = props;
  return BasicStatusCell(cell.row.original);
});
StatusCell.displayName = 'StatusCell';

export const TrackingLink = React.memo((props: CellContext<TListShipment, unknown>) => {
  const { cell } = props;
  return BasicTrackingLink(cell.row.original);
});
TrackingLink.displayName = 'TrackingLink';

export const GiftsCell = React.memo((props: CellContext<TListShipment, unknown>) => {
  const { cell } = props;
  return (
    <div className={clsx(tableStyles.tableSpaceQuaternary)}>
      <span>{cell.row.original.gifts?.items.length}</span>
    </div>
  );
});
GiftsCell.displayName = 'GiftsCell';

export const RecipientCell = React.memo((props: CellContext<TListShipment, unknown>) => {
  const { cell } = props;
  return (
    <div className={clsx(tableStyles.tableSpacePrimary)}>
      <span>{cell.row.original.name}</span>
    </div>
  );
});
RecipientCell.displayName = 'RecipientCell';

// const NotMockedDeliveries = () => {
export const Deliveries = () => {
  const navigate = useNavigate();
  const { id: companyId } = usePrivateCompanyContext();

  const { data, error, loading } = useQueryAllShipments({
    companyId,
  });

  const getRowId = useCallback((shipment: TListShipment) => {
    return shipment.id;
  }, []);

  const onRowClick = useCallback(
    (id: string) => {
      navigate(
        generatePath(`${DELIVERIES_PATH}/${DELIVERY_PATH}/${DETAILS_PATH}`, {
          deliveryId: id,
        }),
      );
    },
    [navigate],
  );

  const { state } = useLocation();

  const [recipientNameFilter, setRecipientNameFilter] = useState<string>();
  const [trackingNumberFilter, setTrackingNumberFilter] = useState<string>();
  const [statusFilter, setStatusFilter] = useState<string>();
  const [sortType, setSortType] = useState<string>('date');

  useEffect(() => {
    if (state === 'Ready to Ship') {
      setStatusFilter('Assembling');
    } else if (state === 'In Transit') {
      setStatusFilter('In Transit');
    }
  }, [state]);

  const filterByRecipientName = useCallback(
    (e: TListShipment) => {
      if (
        recipientNameFilter != null &&
        recipientNameFilter !== '' &&
        (e.gifts?.items || []).filter(
          (g) => g?.recipient?.fullName != null && quickScore(g.recipient.fullName, recipientNameFilter) > 0.7,
        ).length < 1
      ) {
        return false;
      }

      return true;
    },
    [recipientNameFilter],
  );

  const filterByTrackingNumber = useCallback(
    (e: TListShipment) => {
      if (
        trackingNumberFilter != null &&
        trackingNumberFilter !== '' &&
        (e.trackingNumber == null || quickScore(e.trackingNumber, trackingNumberFilter) < 0.7)
      ) {
        return false;
      }

      return true;
    },
    [trackingNumberFilter],
  );

  const filterByStatus = useCallback(
    (e: TListShipment) => {
      if (statusFilter == null) return true;

      const status = getShipmentFinalStatus(e);
      return status === statusFilter;
    },
    [statusFilter],
  );

  const filters = useMemo(() => {
    const filtersList: TFiltersProps['filters'] = [];

    filtersList.push({
      type: 'textInput',
      label: 'Recipient Name',
      onChange: (v) => {
        if (v == null) setRecipientNameFilter(undefined);
        else setRecipientNameFilter(v as string);
      },
      value: recipientNameFilter,
    });

    filtersList.push({
      type: 'textInput',
      label: 'Tracking Number',
      onChange: (v) => {
        if (v == null) setTrackingNumberFilter(undefined);
        else setTrackingNumberFilter(v as string);
      },
      value: trackingNumberFilter,
    });

    filtersList.push({
      type: 'singleSelect',
      label: 'Status',
      options: [
        {
          label: 'Assembling',
          value: 'Assembling',
        },
        {
          label: 'Awaiting Shipment',
          value: 'Awaiting Shipment',
        },
        {
          label: 'Shipping Label Printed',
          value: 'Shipping Label Printed',
        },
        {
          label: 'In Transit',
          value: 'In Transit',
        },
        {
          label: 'Delivered',
          value: 'Delivered',
        },
        {
          label: 'Available for Pickup',
          value: 'Available for Pickup',
        },
        {
          label: 'Returned',
          value: 'Returned',
        },
        {
          label: 'Unavailable',
          value: 'Unavailable',
        },
      ],
      value: statusFilter,
      onChange: (v) => {
        if (v == null) setStatusFilter(undefined);
        else setStatusFilter(v as string);
      },
      includeEmptyOption: true,
    });

    filtersList.push({
      type: 'singleSelect',
      label: 'Order by',
      options: [
        {
          label: 'Shipping Date',
          value: 'date',
        },
        {
          label: 'Status',
          value: 'status',
        },
        {
          label: 'Name',
          value: 'name',
        },
      ],
      value: sortType,
      onChange: (v) => {
        if (v == null) setSortType('date');
        else setSortType(v as string);
      },
      includeEmptyOption: false,
    });

    return filtersList;
  }, [recipientNameFilter, statusFilter, trackingNumberFilter, sortType]);

  const shipments: TListShipment[] = useMemo(() => {
    if (sortType === 'status') {
      return [...data].sort((a, b) => {
        const statusCompare = getShipmentFinalStatus(a).localeCompare(getShipmentFinalStatus(b));
        if (statusCompare == 0) {
          return sortShipments(a, b);
        } else {
          return statusCompare;
        }
      });
    } else if (sortType === 'name') {
      return [...data].sort((a, b) => {
        const nameCompare = (a?.name || '').localeCompare(b?.name || '');
        if (nameCompare == 0) {
          return sortShipments(a, b);
        } else {
          return nameCompare;
        }
      });
    } else {
      return [...data].sort(sortShipments);
    }
  }, [data, sortType]);

  const filteredShipments = useMemo(() => {
    return shipments.filter((s) => {
      return filterByRecipientName(s) && filterByTrackingNumber(s) && filterByStatus(s);
    });
  }, [filterByRecipientName, filterByTrackingNumber, filterByStatus, shipments]);

  if (error) return <ErrorPage error={error} />;
  if (loading) return <LoadingPage />;

  return (
    <TablePage title='Deliveries'>
      <Filters filters={filters} />
      <TableInner<TListShipment>
        data={filteredShipments}
        emptyText={
          shipments.length === 0
            ? 'There are not any shipments sent yet.'
            : 'There are no shipments for the selected filters.'
        }
        expandable={false}
        fixedHeader={true}
        getRowId={getRowId}
        hoverableRows={true}
        onRowClick={(row) => {
          onRowClick(row.original.id);
        }}
        virtual={true}
        columns={[
          {
            header: 'Recipient',
            cell: RecipientCell,
          },
          {
            header: 'Status',
            cell: StatusCell,
          },
          {
            header: 'Shipping Date',
            cell: ({ cell }: CellContext<TListShipment, unknown>) => {
              return (
                <div className={clsx(tableStyles.tableSpaceSecondary)}>
                  <span>{readableDate(cell.row.original.actualShippingDate || cell.row.original.shippingDate)}</span>
                </div>
              );
            },
          },
          {
            header: 'Gifts',
            cell: GiftsCell,
          },
          {
            header: 'Tracking Number',
            cell: TrackingLink,
          },
        ]}
      />
    </TablePage>
  );
};
